import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, catchError} from "rxjs/operators";
import {HttpServiceBase} from "../classes/HttpServiceBase";
import {JsonResponse} from "../interfaces/JsonResponse.interface";

@Injectable({
    providedIn: 'root'
})
export class BackendService extends HttpServiceBase {

    constructor(
        private http: HttpClient,
    ) {
        super();
        this.assign(this);
    }


    public getInstallation(): Observable<any> {
        return this._http.post(this.baseUrl + '/installation', {type: "web", identifier: window?.location?.hostname})
            .pipe(map((response: any) => response),
                catchError(this.handleError.bind(this)));
    }

    public location(params: Object): Observable<any> {
        return this._http.post(this.baseUrl + '/location', params)
            .pipe(map((response: any) => response),
                catchError(this.handleError.bind(this)));
    }

    public getCancellationReasons(): Observable<any> {
        return this._http.post(this.baseUrl + '/cancellation-reasons/list', {})
            .pipe(map((response: any) => response),
                catchError(this.handleError.bind(this)));
    }

    public getRescheduleReasons(): Observable<any> {
        return this._http.post(this.baseUrl + '/reschedule-reasons/list', {})
            .pipe(map((response: any) => response),
                catchError(this.handleError.bind(this)));
    }

    public retrievePendingActions(identifiers: string[] = []) {
        return this._http.post(this.baseUrl + '/pendingActions', {identifiers})
            .pipe(map((response) => response as JsonResponse),
                catchError(this.handleError.bind(this)));
    }

    public skipPendingAction(identifier: string) {
        return this._http.post(this.baseUrl + '/skip-action', {identifier})
            .pipe(map((response) => response as JsonResponse),
                catchError(this.handleError.bind(this)));
    }
}
