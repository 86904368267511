import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {inject} from "@angular/core";


export class HttpServiceBase {
    protected baseUrl: string = "";
    // @ts-ignore
    protected _http: HttpClient;
    // @ts-ignore
    protected _toastr: ToastrService;

    protected translate: TranslateService = inject(TranslateService);

    constructor() {

    }

    /** @description Assigns the necessary dependencies to the service. ALWAYS add HttpClient to the service as 'http'.
     * @param service
     */
    assign(service: any) {
        if (service.http && service.http instanceof HttpClient) {
            this._http = service.http;
        }

        if (service.toastr && service.toastr instanceof ToastrService) {
            this._toastr = service.toastr;
        }

    }

    protected handleError(err: HttpErrorResponse): Observable<never> {
        // just a test ... more could go here
        if (this._toastr) {
            this._toastr.error(err.error?.message ?? this.translate.instant("generic_error_unknown"));
        }

        return throwError(() => ({
            httpError: err,
            service: this.constructor.name
        }));
    }
}
