import {Component, computed, effect, ElementRef, Input, input, signal, viewChild} from '@angular/core';
import {fadeOut, rotateIn, rotateOut} from "../../animations/enter-leave.animation";

@Component({
    selector: 'app-loader',
    standalone: true,
    templateUrl: './loader.component.html',
    styleUrl: './loader.component.scss',
    animations: [
        fadeOut,
        rotateIn,
        rotateOut
    ]
})
export class LoaderComponent {
    @Input() hasBackdrop: boolean = true;
    @Input() hasText: boolean = false;

    showParticles: boolean = false;
    unloadTimeout: NodeJS.Timeout | undefined;

    startLock = signal(false);
    endLock = signal(false);
    loadingLock = computed(() => this.startLock() || this.endLock());

    icon = viewChild<ElementRef>('loaderIcon');

    loading = input(false);
    showing = computed(() => this.loading() || this.loadingLock());

    onAnimationInStart() {
        this.startLock.set(true);
    }

    onAnimationInDone() {
        this.startLock.set(false);
        if (this.icon()?.nativeElement) {
            this.icon()!.nativeElement.classList.remove("out");
        }
        setTimeout(() => {
            if (!this.icon()?.nativeElement) {
                return;
            }
            this.icon()!.nativeElement.classList.add("pulse");
        }, 500);
    }

    onAnimationOutStart() {
        this.endLock.set(true);
    }

    onAnimationOutDone() {
        if (!this.loading()) {
            this.showParticles = true;
        }
    }

    constructor() {
        effect(() => {
            if (this.loading()) {
                this.showParticles = false;
                clearTimeout(this.unloadTimeout);
                return;
            }

            this.unloadTimeout = setTimeout(() => {
                this.showParticles = false;
                this.endLock.set(false);
            }, 800)
        });

        this.showing = computed(() => this.loading() || this.loadingLock());
    }

}
