@if (showing()) {

    <div (click)="$event.stopPropagation(); $event.preventDefault()" class="mini-loader"
         @fadeOut
         [class.background]="hasBackdrop"
         [class.backdrop-blur]="hasBackdrop">
        @if (loading() || startLock()) {
            <img @rotateOut @rotateIn
                 (@rotateIn.start)="onAnimationInStart()"
                 (@rotateIn.done)="onAnimationInDone()"
                 (@rotateOut.start)="onAnimationOutStart()"
                 (@rotateOut.done)="onAnimationOutDone()"
                 src="assets/images/App%20Logo.svg"
                 alt="loader"
                 #loaderIcon
                 class="loader-icon out"/>
        }
        @if (showParticles) {
            <div class="particle"></div>
        }

        @if (hasText) {
            <span class="text-diagnosou-secondary fs-5 fw-bold">Φόρτωση...</span>
        }
    </div>
}
