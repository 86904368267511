<div class="position-relative h-100 w-100">
    <app-loader [loading]="mapLoading() || !options.mapId"></app-loader>

    @if (!!options.mapId) {
        <google-map id="map"
                    height="100%" width="100%"
                    [options]="options"
                    (mapDragend)="dragEnd()"
                    (mapDblclick)="changeLocationTo($event.latLng!)"
                    (mapClick)="hideLabInfo()"
                    (zoomChanged)="zoomChanged()"
                    (mapInitialized)="mapLoad($event)">
            @for (lab of this.nearbyLabs; track lab.id) {
                <map-advanced-marker [position]="lab.location!"
                                     class="pin"
                                     [zIndex]="lab.id === 'me' ? 2 : 1"
                                     (mapClick)="hideLabInfo(); showLabInfo(lab)"
                                     [gmpDraggable]="lab.id === 'me'"
                                     (mapDragend)="pinDragEnd($event)"
                                     [content]="lab.content!">
                </map-advanced-marker>
            }
        </google-map>

        @if (showEmptyLabsMsg) {
            <div class="lab-details-container">
                <div class="my-4 alert border border-diagnosou-secondary alert-purple text-center">
                    <h5 class="alert-heading">{{ 'lab_list_empty_title' | translate }}</h5>
                    <p class="m-0">
                        <i class="fa fa-map-location-dot"></i>
                        {{ 'lab_list_empty_subtitle' | translate }}
                    </p>
                </div>
            </div>
        }
    }


    @if (labSelected) {
        <div class="lab-details-container" (click)="hideLabInfo()">
            <div (click)="$event.stopPropagation()"
                 @fade class="lab-details shadow d-flex flex-column align-items-baseline gap-1 p-3">
                <div class="d-flex w-100 gap-1 justify-content-between">
            <span [ngStyle]="{textOverflow: 'ellipsis'}"
                  class="title lab-title text-truncate text-black text-nowrap overflow-hidden">
                @if (selectedLab?.logo) {
                    <img [src]="selectedLab?.logo" alt="" height="28" class="me-1">
                }
                {{ selectedLab?.name }}
            </span>
                    <span class="badge-km align-self-baseline p-1">
                {{ (selectedLab?.distance ?? 0)|number:'1.0-1' }} km
            </span>
                </div>
                <div *ngIf="selectedLab?.rating" class="d-flex gap-2 align-items-baseline">
                    <span class="number">{{ selectedLab?.rating?.toFixed(1) }}</span>
                    <div>
                        @for (e of [].constructor(5); let i = $index; track i) {
                            @if ((selectedLab?.rating ?? 0) > i) {
                                <i class="fa text-warning"
                                   [class.fa-star]="(selectedLab?.rating ?? 0) >= i + .75"
                                   [class.fa-star-half]="(selectedLab?.rating ?? 0) < i + .75">
                                </i>
                            }
                        }
                    </div>
                    ({{ selectedLab?.ratingCount }})
                </div>
                <div class="mb-1">
                    <span>{{ selectedLab?.address }}</span>
                </div>
                <button class="btn w-100 btn-gradient" (click)="selectCurrentLab()">
                    {{ 'lab_details_action_button' | translate }}
                </button>

            </div>
        </div>
    }
</div>
