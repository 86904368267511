import {animate, state, style, transition, trigger} from "@angular/animations";

export const fade = trigger('fade', [
    state('void', style({opacity: 0})),
    transition('void => *', animate(200)),
    transition('* => void', animate(200))
])
export const fadeIn = trigger('fadeIn', [
    state('void', style({opacity: 0})),
    transition('void => *', animate(300)),
])
export const fadeOut = trigger('fadeOut', [
    state('void', style({opacity: 0})),
    transition('* => void', animate(300))
])

export const rotateIn = trigger('rotateIn', [
    transition(':enter', [
        style({transform: 'scale(0) rotate(75deg)'}),
        animate('0.3s ease-in-out', style({transform: 'scale(1.5) rotate(-10deg)'})),
        animate('0.2s ease-in-out', style({transform: 'scale(1) rotate(0deg)'}))
    ]),
]);
export const rotateOut = trigger('rotateOut', [
        transition(':leave', [
            animate('0.2s ease-in-out', style({transform: 'scale(1.2) rotate(-5deg)'})),
            animate('0.3s ease-in-out', style({transform: 'scale(0) rotate(-90deg)'}))
        ])
    ])
